import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';

import FileCheck from 'images/check-icon.svg';

import Container from 'components/Container';
import {
  Wrapper,
  Title,
  Table,
  TableTitle,
  ItemsWrapper,
  Item,
  Price,
  TableFooter,
  Small,
  AdditionalInfo,
  Description,
} from './styled';

const Pricing = () => (
  <Wrapper>
    <Container>
      <Title>Pricing</Title>
      <Description>
        <Row>
          <Col md={6} lg={6}>
            <p>
              There are many factors involved in determining price. We consider
              your home size, condition, location, your needs and preferences, the
              number of occupants, presence of pets, and frequency of cleaning.
            </p>
            <p>
              We custom price each home and provide you with a price quote during
              our free, no obligation consultation.
            </p>
          </Col>
          <Col md={6} lg={6}>
            <p>
              The cost is based on the time spent inside your home. Our standard
              rate is $25.00 per hour, per person. There is a four hour (total)
              minimum to receive this low rate.
            </p>
            <p>
              For our first visit we usually need more time to deep clean certain
              areas of your home and the price is higher: $35.00 per hour, per
              person so we can maintain that standard of cleaning with each
              subsequent visit.
            </p>
          </Col>
        </Row>
      </Description>

      <Table>
        <TableTitle>
          The following are the price ranges for cleaning typical homes on
          regular, bi-weekly basis:
        </TableTitle>

        <ItemsWrapper>
          <Item>
            <div>Apart.</div>
            300-400 sq.ft
            <br />
            <Price>75</Price>
          </Item>
          <Item>
            <div>Apart.</div>
            500-700 sq.ft
            <br />
            <Price>100</Price>
          </Item>
          <Item>
            <div>Apart.</div>
            800-1,000 sq.ft
            <br />
            <Price>125</Price>
          </Item>
          <Item>
            <div>Apart.</div>
            1100-1,400 sq.ft
            <br />
            <Price>150</Price>
          </Item>
          <Item>
            <div>Apart.</div>
            1500-2,000 sq.ft
            <br />
            <Price>200</Price>
          </Item>
        </ItemsWrapper>

        <TableFooter>
          <Small>Rates based on Bi-weekly service</Small>
          For apartments bigger than 2,000 sq. ft - please call
        </TableFooter>
      </Table>
    </Container>

    <AdditionalInfo>
      <Container>
        <Row>
          <Col md={3} lg={3}>
            <img src={FileCheck} alt="" />
            <p>Payments are due at the time of service.</p>
          </Col>
          <Col md={3} lg={3}>
            <img src={FileCheck} alt="" />
            <p>
              Payments will be expected in form of a cash or check (please make
              all checks payable to cash or Joanna Cleaning Service only).
            </p>
          </Col>
          <Col md={3} lg={3}>
            <img src={FileCheck} alt="" />
            <p>
              Tipping is encouraged, please do not include in the total amount
              on the check.
            </p>
          </Col>
          <Col md={3} lg={3}>
            <img src={FileCheck} alt="" />
            <p>There is a $40.00 fee for all returned checks.</p>
          </Col>
        </Row>
      </Container>
    </AdditionalInfo>
  </Wrapper>
);

export default Pricing;
