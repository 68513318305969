import styled from 'styled-components';
import List from 'components/List';
import { media } from 'themes';

export const Wrapper = styled.section`
  padding-top: 112px;

  ${media.sm`
    padding-top: 20px;
  `}
`;

export const Title = styled.h1`
  color: #474c57;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;

  ${media.md`
    margin-bottom: 0;
    margin-left: 32px;
  `}

  ${media.sm`
    margin-left: 0;
    font-size: 24px;
    line-height: 32px;
  `}
`;

export const Subtitle = styled.h2`
  color: #474c57;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;

  ${media.sm`
    position: relative;
    color: #75A605;
  `}
`;

export const Arrow = styled.div`
  display: none;

  ${media.sm`
    display: block;
    position: absolute;
    top: -5px;
    right: 0;
  `}
`;

export const PlaceList = styled(List)`
  margin-top: 12px;

  ${media.sm`
    display: none;
    margin-bottom: 30px;

    ${({ visible }) => visible && `
      display: block;
    `}
  `}
`;

export const Unavailable = styled.div`
  color: #75a605;
  font-size: 14px;
  line-height: 28px;
  margin-top: 50px;
  padding-top: 40px;
  border-top: 2px dashed rgba(117, 166, 5, 0.2);
  margin-right: 40px;

  ${media.sm`
    margin-top: 24px;
    padding-top: 24px;
  `}
`;

export const TitleWrapper = styled.div`
  ${media.md`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
  `}

  ${media.sm`
    img {
      display: none;
    }
  `}
`;

export const GreenBox = styled.div`
  ${media.sm`
    ${({ visible }) => visible && `
      background-color: #f4fbe3;
      margin-left: -15px;
      margin-right: -15px;
      padding: 15px 15px 5px 15px;
      margin-bottom: 10px;

      ${Arrow} {
        transform: rotate(180deg);
        top: 5px;        
      }
    `}
  `}
`