import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { media } from 'themes';

export const Wrapper = styled.section`
  padding-top: 128px;

  ${media.md`
    padding-top: 56px;
  `}
`;

export const Image = styled(Img)`
  margin-top: 21px;
  margin-right: 70px;
  float: right;

  ${media.md`
    margin-top: -80px;
  `}

  ${media.sm`
    display: none !important;
  `}
`;

export const MobileImage = styled(Img)`
  display: none;

  ${media.sm`
    display: block !important;
    height: 300px;
    margin-left: -15px;
    margin-right: -15px;
  `}
`;

export const Title = styled.h2`
  color: #474c57;
  font-size: 32px;
  line-height: 40px;
  margin-top: 40px;

  ${media.md`
    margin: 0 0 0 32px;
  `}

  ${media.sm`
    font-size: 24px;
    line-height: 32px;
    margin-left: 0;
  `}
`;

export const Description = styled.p`
  color: #5a5f6a;
  font-size: 14px;
  line-height: 28px;
  margin-top: 22px;
`;

export const Subtitle = styled.h3`
  color: #474c57;
  margin-top: 32px;
  margin-bottom: 35px;
  font-size: 14px;
  font-weight: 600;

  ${media.sm`
    line-height: 30px;
  `}
`;

export const More = styled.div`
  position: relative;
  z-index: 2;
  display: inline-block;
  margin-top: -40px;
  border-radius: 10px;
  background-color: #75a605;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  padding: 24px 32px;

  ${media.md`
    margin-top: 50px;
  `}

  ${media.sm`
    display: block;
    margin: 40px -15px 0 -15px;
    border-radius: 0;
    padding: 24px 15px;
  `}
`;

export const Button = styled(Link)`
  display: inline-block;
  border-radius: 30.5px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px -2px rgba(78, 112, 0, 0.41);
  color: #75a605;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  padding: 8px 26px;
  margin-top: 16px;
`;

export const TitleWrapper = styled.div`
  ${media.md`
    display: flex;
    align-items: center;
  `}

  ${media.sm`
    img {
      display: none;
    }
  `}
`;
