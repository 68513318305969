import React, { useState } from 'react';
import { Col } from 'styled-bootstrap-grid';

import FileArrowDown from 'images/arrow-down.svg';
import FileSweep from 'images/sweep-icon.svg';
import FileVacum from 'images/vacuum-icon.svg';
import Container from 'components/Container';
import ListItem from 'components/ListItem';

import {
  Wrapper,
  ServiceRow,
  Title,
  Icons,
  Note,
  Subtitle,
  PlaceList,
  Category,
  RelativeCol,
  TitleWrapper,
  SubtitleMobile,
  MobileContent,
  GreenSection,
  MobileSection,
  Arrow,
} from './styled';

const OurServices = () => {
  const [activeSection, setActiveSection] = useState(null);

  const isActiveSection = section => section === activeSection;

  return (
    <Wrapper>
      <Container>
        <ServiceRow>
          <Col lg={3}>
            <TitleWrapper>
              <Title>Our services</Title>
              <Icons>
                <img src={FileSweep} alt="" />
                <img src={FileVacum} alt="" />
              </Icons>
            </TitleWrapper>

            <Note green hideMobile>
              Please let us know, if you would like to clean windows. We need
              this information beforehand.
            </Note>
            <Note hideMobile>
              Move in/move out, post renovating cleaning - please call for more
              info.
            </Note>
          </Col>
          <Col md={6} lg={5}>
            <GreenSection visible={isActiveSection('BASIC')}>
              <Category onClick={() => setActiveSection('BASIC')}>
                Basic Cleaning
                <Arrow>
                  <img src={FileArrowDown} alt="" />
                </Arrow>
              </Category>
              <MobileSection visible={isActiveSection('BASIC')}>
                <Subtitle>Rooms</Subtitle>
                <PlaceList>
                  <ListItem>furnitures dusted</ListItem>
                  <ListItem>light fixtures dusted </ListItem>
                  <ListItem>mini blinds and window sills dusted</ListItem>
                  <ListItem>ceiling fans dusted</ListItem>
                  <ListItem>mirrors cleaned and polished </ListItem>
                  <ListItem>pictures frames dusted </ListItem>
                  <ListItem>floors vacuumed and washed</ListItem>
                  <ListItem>changed sheets and made beds </ListItem>
                  <ListItem>emptied trash</ListItem>
                </PlaceList>
              </MobileSection>

              <MobileContent visible={isActiveSection('BASIC')}>
                <SubtitleMobile>Kitchens</SubtitleMobile>
                <PlaceList>
                  <ListItem>everything cleaned on countertops</ListItem>
                  <ListItem>cleaned sinks and windows over sinks</ListItem>
                  <ListItem>window sills dusted</ListItem>
                  <ListItem>oven outside </ListItem>
                  <ListItem>microwave inside and out</ListItem>
                  <ListItem>refrigerator outside </ListItem>
                  <ListItem>cabinets washed outside </ListItem>
                  <ListItem>metal fixtures cleaned and polished</ListItem>
                  <ListItem>floors vacuumed and washed</ListItem>
                  <ListItem>emptied trash</ListItem>
                </PlaceList>

                <SubtitleMobile>Bathrooms</SubtitleMobile>
                <PlaceList>
                  <ListItem> sinks cleaned</ListItem>
                  <ListItem>tubs-showers cleaned </ListItem>
                  <ListItem>tiles, tracks and doors cleaned</ListItem>
                  <ListItem>vanities / countertops cleaned, </ListItem>
                  <ListItem>toilets inside and out</ListItem>
                  <ListItem>mirrors cleaned and shined</ListItem>
                  <ListItem>metal fixtures cleaned and polished</ListItem>
                  <ListItem>floors vacuumed and washed</ListItem>
                  <ListItem>emptied trash.</ListItem>
                </PlaceList>
              </MobileContent>
            </GreenSection>
          </Col>
          <RelativeCol md={6} lg={4}>
            <GreenSection visible={isActiveSection('DEEP')}>
              <Category onClick={() => setActiveSection('DEEP')}>
                Deep Cleaning
                <Arrow>
                  <img src={FileArrowDown} alt="" />
                </Arrow>
              </Category>
              <MobileSection visible={isActiveSection('DEEP')}>
                <Subtitle>Living Areas and Bedrooms</Subtitle>
                <PlaceList>
                  <ListItem>carpets vacuumed and edged</ListItem>
                  <ListItem>baseboards dusted and wiped down</ListItem>
                  <ListItem>vacuumed and mopped hard floor surfaces</ListItem>
                  <ListItem>stairs vacuumed</ListItem>
                  <ListItem>tidy room appearance</ListItem>
                  <ListItem>furnitures dusted </ListItem>
                  <ListItem>made beds</ListItem>
                  <ListItem>removed cobwebs</ListItem>
                  <ListItem>mini blinds dusted</ListItem>
                </PlaceList>
              </MobileSection>

              <MobileContent visible={isActiveSection('DEEP')}>
                <SubtitleMobile>Kitchens</SubtitleMobile>
                <PlaceList>
                  <ListItem> baseboards dusted and wiped down</ListItem>
                  <ListItem>
                    cleaned small countertop appliances inside and out
                  </ListItem>
                  <ListItem>cleaned refrigerator inside and out</ListItem>
                  <ListItem>outside of range hood cleaned </ListItem>
                  <ListItem>sinks cleaned and disinfected</ListItem>
                  <ListItem>shined and cleaned chrome fixtures</ListItem>
                  <ListItem>countertops cleaned and disinfected</ListItem>
                  <ListItem>kitchen cabinets cleaned inside and out </ListItem>
                  <ListItem>microwaves cleaned inside and out</ListItem>
                  <ListItem>cleaned table and chairs</ListItem>
                  <ListItem>floors vacuumed and mopped</ListItem>
                  <ListItem>emptied trash</ListItem>
                  <ListItem>cobwebs removed</ListItem>
                </PlaceList>

                <SubtitleMobile mobile>Bathrooms</SubtitleMobile>
                <PlaceList>
                  <ListItem>
                    tile walls and bathtubs cleaned and disinfected
                  </ListItem>
                  <ListItem>
                    shower and shower doors cleaned and disinfected
                  </ListItem>
                  <ListItem>carpets vacuumed and edged</ListItem>
                  <ListItem>baseboards dusted and wiped down</ListItem>
                  <ListItem>mirrors cleaned and shined</ListItem>
                  <ListItem>
                    sinks and counters cleaned and disinfected
                  </ListItem>
                  <ListItem>floors washed and disinfected</ListItem>
                  <ListItem>carpeting vacuumed</ListItem>
                  <ListItem>cleaned and disinfected toilet</ListItem>
                  <ListItem>shined and cleaned chrome fixtures</ListItem>
                  <ListItem>
                    medicine cabinets cleaned inside and out, drawers
                  </ListItem>
                  <ListItem>cobwebs removed </ListItem>
                </PlaceList>
              </MobileContent>
            </GreenSection>
          </RelativeCol>
        </ServiceRow>

        <ServiceRow>
          <Col lg={3} hiddenLgDown>
            <Subtitle>Kitchens</Subtitle>
          </Col>
          <Col md={6} lg={5} hiddenMdDown>
            <SubtitleMobile>Kitchens</SubtitleMobile>
            <PlaceList>
              <ListItem>everything cleaned on countertops</ListItem>
              <ListItem>cleaned sinks and windows over sinks</ListItem>
              <ListItem>window sills dusted</ListItem>
              <ListItem>oven outside </ListItem>
              <ListItem>microwave inside and out</ListItem>
              <ListItem>refrigerator outside </ListItem>
              <ListItem>cabinets washed outside </ListItem>
              <ListItem>metal fixtures cleaned and polished</ListItem>
              <ListItem>floors vacuumed and washed</ListItem>
              <ListItem>emptied trash</ListItem>
            </PlaceList>
          </Col>
          <Col md={6} lg={4} hiddenMdDown>
            <SubtitleMobile>Kitchens</SubtitleMobile>
            <PlaceList>
              <ListItem> baseboards dusted and wiped down</ListItem>
              <ListItem>
                cleaned small countertop appliances inside and out
              </ListItem>
              <ListItem>cleaned refrigerator inside and out</ListItem>
              <ListItem>outside of range hood cleaned </ListItem>
              <ListItem>sinks cleaned and disinfected</ListItem>
              <ListItem>shined and cleaned chrome fixtures</ListItem>
              <ListItem>countertops cleaned and disinfected</ListItem>
              <ListItem>kitchen cabinets cleaned inside and out </ListItem>
              <ListItem>microwaves cleaned inside and out</ListItem>
              <ListItem>cleaned table and chairs</ListItem>
              <ListItem>floors vacuumed and mopped</ListItem>
              <ListItem>emptied trash</ListItem>
              <ListItem>cobwebs removed</ListItem>
            </PlaceList>
          </Col>
        </ServiceRow>

        <ServiceRow>
          <Col hiddenLgDown lg={3}>
            <Subtitle>Bathrooms</Subtitle>
          </Col>
          <Col md={6} lg={5} hiddenMdDown>
            <SubtitleMobile>Bathrooms</SubtitleMobile>
            <PlaceList>
              <ListItem> sinks cleaned</ListItem>
              <ListItem>tubs-showers cleaned </ListItem>
              <ListItem>tiles, tracks and doors cleaned</ListItem>
              <ListItem>vanities /countertops cleaned, </ListItem>
              <ListItem>toilets inside and out</ListItem>
              <ListItem>mirrors cleaned and shined</ListItem>
              <ListItem>metal fixtures cleaned and polished</ListItem>
              <ListItem>floors vacuumed and washed</ListItem>
              <ListItem>emptied trash.</ListItem>
            </PlaceList>
          </Col>
          <Col md={6} lg={4} hiddenMdDown>
            <SubtitleMobile mobile>Bathrooms</SubtitleMobile>
            <PlaceList>
              <ListItem>
                tile walls and bathtubs cleaned and disinfected
              </ListItem>
              <ListItem>
                shower and shower doors cleaned and disinfected
              </ListItem>
              <ListItem>carpets vacuumed and edged</ListItem>
              <ListItem>baseboards dusted and wiped down</ListItem>
              <ListItem>mirrors cleaned and shined</ListItem>
              <ListItem>sinks and counters cleaned and disinfected</ListItem>
              <ListItem>floors washed and disinfected</ListItem>
              <ListItem>carpeting vacuumed</ListItem>
              <ListItem>cleaned and disinfected toilet</ListItem>
              <ListItem>shined and cleaned chrome fixtures</ListItem>
              <ListItem>
                medicine cabinets cleaned inside and out, drawers
              </ListItem>
              <ListItem>cobwebs removed </ListItem>
            </PlaceList>
          </Col>
        </ServiceRow>
      </Container>
    </Wrapper>
  );
};

export default OurServices;
