import styled from 'styled-components';
import { media } from 'themes';

export const Wrapper = styled.section`
  margin-top: 150px;

  ${media.sm`
    margin-top: 40px;
  `}
`;

export const Description = styled.div`
  p {
    color: #5a5f6a;
    font-size: 14px;
    line-height: 28px;

    & + p {
      margin-top: 28px;
    }
  }
`;

export const Title = styled.h2`
  color: #474c57;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 22px;

  ${media.sm`
    font-size: 24px;
    line-height: 32px;
  `}
`;

export const Table = styled.div`
  position: relative;
  z-index: 1;
  border-radius: 219.5px;
  background-color: #75a605;
  padding-top: 35px;
  padding-bottom: 20px;
  margin-top: 70px;

  margin-left: -135px;
  margin-right: -135px;
  padding-left: 135px;
  padding-right: 135px;

  ${media.md`
    border-radius: 20px;
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 25px;
    padding-right: 25px;
  `}

  ${media.sm`
    margin: 32px -15px;
    padding: 0;
    border-radius: 0;
  `}
`;

export const TableTitle = styled.h3`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  padding: 0 350px;
  margin-bottom: 26px;

  ${media.md`
    padding: 0 120px;
  `}

  ${media.sm`
    padding: 23px 25px;
    font-size: 18px;
    line-height: 28px;
  `}
`;

export const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.md`
    flex-wrap: wrap;
    justify-content: center;
  `}
`;

export const Item = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;

  ${media.md`
    flex: 1 0 30%;
    max-width: 240px;
    margin-bottom: 20px;
  `}

  ${media.sm`
    flex: 1 0 50%;
    max-width: auto;
  `}
`;

export const Price = styled.span`
  position: relative;
  font-size: 32px;
  line-height: 56px;

  &:before {
    content: '$';
    position: absolute;
    top: 6px;
    left: -15px;
    color: #95c517;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
`;

export const TableFooter = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 44px;
  text-align: center;
  margin-top: 15px;

  ${media.sm`
    line-height: 28px;
    padding: 0 40px 26px 40px;
  `}
`;

export const Small = styled.div`
  font-size: 14px;
  font-weight: normal;

  ${media.sm`
    margin-bottom: 20px;
  `}
`;

export const AdditionalInfo = styled.div`
  background-color: #f3f8ff;
  padding-top: 235px;
  margin-top: -180px;
  color: #474c57;
  font-size: 12px;
  line-height: 24px;
  padding-bottom: 72px;

  ${media.sm`
    padding-bottom: 36px;
  `}

  p {
    margin-top: 25px;

    ${media.sm`
      margin-top: 16px;
      margin-bottom: 24px;
    `}
  }
`;
