import React, { useState } from 'react';
import { Row, Col } from 'styled-bootstrap-grid';

import FileArrowDown from 'images/arrow-down.svg';
import FileLocation from 'images/location-icon.svg';
import Container from 'components/Container';
import ListItem from 'components/ListItem';

import {
  Wrapper,
  Title,
  Subtitle,
  PlaceList,
  Unavailable,
  TitleWrapper,
  GreenBox,
  Arrow,
} from './styled';

const Places = () => {
  const [activeSection, setActiveSection] = useState(null);

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col lg={3}>
            <TitleWrapper>
              <Title>Where we serve?</Title>
              <img src={FileLocation} alt="" />
            </TitleWrapper>
          </Col>
          <Col md={4} lg={3}>
            <GreenBox visible={activeSection === 'MANHATTAN'}>
              <Subtitle onClick={() => setActiveSection('MANHATTAN')}>
                Manhattan
                <Arrow>
                  <img src={FileArrowDown} alt="" />
                </Arrow>
              </Subtitle>
              <PlaceList visible={activeSection === 'MANHATTAN'}>
                <ListItem>Financial District</ListItem>
                <ListItem>Battery Park City</ListItem>
                <ListItem>Soho</ListItem>
                <ListItem>Greenwich Village</ListItem>
                <ListItem>Tribeca </ListItem>
                <ListItem>Lower East Side</ListItem>
                <ListItem>East Village</ListItem>
                <ListItem>Chelsea</ListItem>
                <ListItem>West Side</ListItem>
                <ListItem>Upper West Side</ListItem>
                <ListItem>East Side</ListItem>
                <ListItem>Upper East Side</ListItem>
              </PlaceList>
            </GreenBox>
          </Col>
          <Col md={4} lg={3}>
            <GreenBox visible={activeSection === 'BROOKLYN'}>
              <Subtitle onClick={() => setActiveSection('BROOKLYN')}>
                Brooklyn
                <Arrow>
                  <img src={FileArrowDown} alt="" />
                </Arrow>
              </Subtitle>
              <PlaceList visible={activeSection === 'BROOKLYN'}>
                <ListItem>Bensonhurst</ListItem>
                <ListItem>Bay Ridge</ListItem>
                <ListItem>Borough Park</ListItem>
                <ListItem>Sunset Park</ListItem>
                <ListItem>Midwood</ListItem>
                <ListItem>Prospect Park</ListItem>
                <ListItem>Park Slope</ListItem>
                <ListItem>Crown Heights</ListItem>
                <ListItem>Carroll Gardens</ListItem>
                <ListItem>Fort Greene</ListItem>
                <ListItem>Brooklyn Heights</ListItem>
                <ListItem>Dumbo</ListItem>
                <ListItem>Bedford Stuyvesant</ListItem>
                <ListItem>Williamsburg</ListItem>
                <ListItem>Greenpoint</ListItem>
              </PlaceList>
            </GreenBox>
          </Col>
          <Col md={4} lg={3}>
            <GreenBox visible={activeSection === 'QUEENS'}>
              <Subtitle onClick={() => setActiveSection('QUEENS')}>
                Queens
                <Arrow>
                  <img src={FileArrowDown} alt="" />
                </Arrow>
              </Subtitle>
              <PlaceList visible={activeSection === 'QUEENS'}>
                <ListItem>Bushwick</ListItem>
                <ListItem>Long Island City</ListItem>
                <ListItem>Astoria</ListItem>
                <ListItem>Jackson Heights</ListItem>
                <ListItem>Woodside</ListItem>
                <ListItem>Rego Park</ListItem>
              </PlaceList>
            </GreenBox>

            <Unavailable>
              We do not serve The Bronx, Staten Island and Long Island at this
              moment.
            </Unavailable>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Places;
