import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/Seo';
import ContactBox from 'components/ContactBox';

import Places from 'content/Services/Places';
import Preparation from 'content/Services/Preparation';
import OurServices from 'content/Services/OurServices';
import Pricing from 'content/Services/Pricing';

const Services = () => (
  <Layout>
    <SEO title="Services" />

    <Places />
    <Preparation />
    <OurServices />
    <Pricing />

    <ContactBox />
  </Layout>
);

export default Services;
