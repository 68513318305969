import styled from 'styled-components';

const ListItem = styled.section`
  position: relative;
  padding-left: 20px;
  letter-spacing: -0.01em;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 4px;
    height: 4px;
    background-color: #75a605;
    border-radius: 50%;
  }

  & + & {
    margin-top: 8px;
  }
`;

export default ListItem;
