import styled from 'styled-components';
import List from 'components/List';
import { Row, Col } from 'styled-bootstrap-grid';
import { media } from 'themes';

export const Wrapper = styled.section`
  padding-top: 112px;

  ${media.sm`
    padding-top: 40px;
  `}
`;

export const Title = styled.h1`
  color: #474c57;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;

  ${media.md`
    margin-bottom: 0;
    margin-left: 32px;
  `}

  ${media.sm`
    font-size: 24px;
    margin-left: 0;
  `}
`;

export const Subtitle = styled.h2`
  color: #474c57;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
`;

export const PlaceList = styled(List)`
  margin-top: 12px;
`;

export const Icons = styled.div`
  img + img {
    margin-left: 12px;
  }
`;

export const Note = styled.div`
  color: #5a5f6a;
  font-size: 14px;
  line-height: 24px;
  margin-top: 16px;
  padding-right: 60px;

  ${({ green }) =>
    green &&
    `
    color: #75A605;
  `}

  ${media.md`
    ${({ hideMobile }) => hideMobile && `
      display: none;
    `}
  `}
`;

export const Category = styled.h2`
  position: relative;
  color: #75a605;
  font-size: 32px;
  line-height: 52px;
  margin-bottom: 24px;

  ${media.sm`
    font-size: 18px;
    line-height: 40px;
  `}
`;

export const Arrow = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 16px;

  ${media.sm`
    display: block;
  `}
`;


export const ServiceRow = styled(Row)`
  margin-bottom: 82px;

  ${media.sm`
    margin-bottom: 0;
  `}
`;

export const RelativeCol = styled(Col)`
  position: relative;
`;

export const GreenBox = styled.div`
  position: absolute;
  top: -40px;
  left: -30px;
  right: -40px;
  z-index: -1;
  height: 1507px;
  opacity: 0.53;
  border-radius: 20px;
  background-color: #f4fbe3;

  ${media.md`
    left: -15px;
    right: -15px;
    height: 1540px;
  `}

  ${media.sm`
    display: none;
  `}
`;

export const TitleWrapper = styled.div`
  ${media.md`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 80px;
  `}

  ${media.sm`
    margin-bottom: 20px;
    
    img {
      display: none;
    }
  `}
`;

export const SubtitleMobile = styled(Subtitle)`
  display: none;

  ${media.md`
    display: block;
  `}
`

export const MobileContent = styled.div`
  display: none;

  ${media.sm`
    display: none;

    ${Subtitle} {
      margin-top: 24px;
    }

    ${({ visible }) => visible && `
      display: block;
    `}
  `}
`

export const GreenSection = styled.div`
  ${media.sm`
    ${({ visible }) => visible && `
      margin-left: -15px;
      margin-right: -15px;
      padding: 20px 15px 25px 15px;
      background-color: rgba(244, 251, 227, 0.5);
      margin-bottom: 10px;

      ${Arrow} {
        transform: scaleY(-1);
        top: 5px;
      }
    `}
  `}
`

export const MobileSection = styled.div`
  ${media.sm`
    display: none;

    ${({ visible }) => visible && `
      display: block;
    `}
  `}
`