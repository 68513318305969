import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import { StaticQuery, graphql } from 'gatsby';

import List from 'components/List';
import Clearfix from 'components/Clearfix';
import ListItem from 'components/ListItem';
import Container from 'components/Container';
import FilePreparation from 'images/preparation-icon.svg';

import {
  Wrapper,
  Image,
  MobileImage,
  Title,
  Description,
  Subtitle,
  More,
  Button,
  TitleWrapper,
} from './styled';

const Preparation = () => (
  <Wrapper>
    <Container>
      <StaticQuery
        query={graphql`
          query {
            headerImage: file(
              relativePath: { eq: "services-preparation.png" }
            ) {
              childImageSharp {
                fixed(height: 440, quality: 100) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }

            headerImageMobile: file(
              relativePath: { eq: "services-preparation.png" }
            ) {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <Row>
            <Col lg={6} hiddenLgDown>
              <Image fixed={data.headerImage.childImageSharp.fixed} fadeIn />

              <Clearfix />

              <More>
                <div>Need more information?</div>
                <Button to="/faq">FAQ Page </Button>
              </More>
            </Col>
            <Col lg={6}>
              <TitleWrapper>
                <img src={FilePreparation} alt="" />
                <Title>Preparations</Title>
              </TitleWrapper>
              <Description>
                Our standard cleaning supplies include regular grocery store
                type chemicals that are used in most homes today. We also offer
                biodegradable cleaning supplies that are all natural,
                environmentally safe, and sensitive to those who may have
                allergies.
              </Description>

              <Subtitle>
                Things you need to have at your home in before we come:
              </Subtitle>

              <Row>
                <Col md={6} lg={6}>
                  <List>
                    <ListItem>sponges with scrubbing side</ListItem>
                    <ListItem>Magic Erasers</ListItem>
                    <ListItem>Brillo pads</ListItem>
                    <ListItem>one full bottle of Windex</ListItem>
                    <ListItem>Easy Off- yellow oven spray</ListItem>
                    <ListItem>Clorox with bleach spray</ListItem>
                  </List>
                </Col>
                <Col md={6} lg={6}>
                  <List>
                    <ListItem>Ajax powder, Comet or Soft Scrub</ListItem>
                    <ListItem>Swiffer Duster- yellow box</ListItem>
                    <ListItem>Swiffer Sweeper- green box (dry)</ListItem>
                    <ListItem>Murphy’s Oil Soap, Mr. Clean or Fabuloso</ListItem>
                    <ListItem>mop</ListItem>
                    <ListItem>vacuum plus extra vacuum cleaner bag or broom</ListItem>
                  </List>
                </Col>
              </Row>
            </Col>
            <Col hiddenLgUp>
              <More>
                <div>Need more information?</div>
                <Button to="/faq">FAQ Page </Button>
              </More>
              <Image fixed={data.headerImage.childImageSharp.fixed} fadeIn />
              <MobileImage fluid={data.headerImageMobile.childImageSharp.fluid} fadeIn />

              <Clearfix />
            </Col>
          </Row>
        )}
      />
    </Container>
  </Wrapper>
);

export default Preparation;
